.site-header {
	padding-top: 2rem;
	position: relative;
	z-index: 20;
}

/*==============================
=            navbar            =
==============================*/
.site-header .icon {
	fill: $blue;
	stroke: $blue;
}
#navbarResponsive {
	position: relative;
}
.site-header .nav.navbar-nav {
  position: absolute;
  bottom: 0;
  left: 33%;
    @media screen and (max-width: 1200px ) {
  		margin-bottom: 0.17rem;
  	}
  @media screen and (max-width: 992px ) {
		left: 1rem;
		margin-bottom: 0.46rem;
	}
	@media screen and (max-width: 767px ) {
		position: relative;
		left: 0;
		background-color: $maroon;
	}
}
.site-header .navbar {
	padding: 0;
	background: rgb(114, 17, 61);
	background: -moz-linear-gradient(270deg, rgb(114, 17, 61) 33%, rgb(255, 255, 255) 33%);
	background: -webkit-linear-gradient(270deg, rgb(114, 17, 61) 33%, rgb(255, 255, 255) 33%);
	background: -o-linear-gradient(270deg, rgb(114, 17, 61) 33%, rgb(255, 255, 255) 33%);
	background: -ms-linear-gradient(270deg, rgb(114, 17, 61) 33%, rgb(255, 255, 255) 33%);
	background: linear-gradient(0deg, rgb(114, 17, 61) 33%, rgb(255, 255, 255) 33%);
}
.site-header {
	.navbar-brand{
		max-width: 30%;
		@media screen and (max-width: 992px ) {
			max-width: 100%;
			padding-bottom: 3rem;
		}
		@media screen and (max-width: 767px ) {
			float: none;
		}

	}
}
.navbar-nav .nav-link {
	color: $dove-grey;
	text-transform: uppercase;
	font-weight: bold;
	padding-bottom: 0.79rem;
	&.active,
	&:hover {
		color: #fff;

	}
}
.navbar-nav .nav-item + .nav-item {
	margin-left: 5rem;
	@media screen and (max-width: 1199px) {
		margin-left: 3rem;
	}
	@media screen and (max-width: 991px) {
		margin-left: 4.7rem;
	}
}
.navbar-nav .nav-item {
	@media screen and (max-width: 767px) {
		margin-left: 1rem !important;
	}
}
.navbar-toggler .burger-icon {
	stroke: $maroon;
	fill: $maroon;
	width: 32px;
}
.navbar-toggler,
.navbar-toggler:focus {
	outline: none;
	position: absolute;
	padding: 0;
	right: 0;
	@media screen and (min-width: 768px) {
		display: none;
	}
}
.navbar-nav .arrow {
  width: 30px;
  visibility: hidden;
  height: 30px;
  background-color: $yellow;
  position: absolute;
  top: 3px;
  right: 15px;
  text-align: center;
  line-height: 30px;
  font-size: 23px !important;
  color: #fff;
  cursor: default;
  letter-spacing: normal;
  @media screen and ( max-width: 767px ) {
  	visibility: visible;
  }
}
.site-header .dropdown-toggle::after {
	@media screen and (max-width: 767px) {
		display: none;
	}
}
.site-header .dropdown:hover {
	.dropdown-menu {
		display: block;
		margin: 0;	
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}

.site-header .dropdown-menu {

	background-color: $blue;
	padding: 0;
	border: 0;

	.dropdown-item {
		text-transform: uppercase;
		color: #fff;
		font-weight: bold;
		padding: 1.5rem;
		border-bottom: 1px solid $dove-grey;
		&:hover,
		&.active {
			background-color: $yellow;
		}
		&:last-child {
			border-bottom: 0;
		}
	}

}

@media screen and (max-width: 767px) {
	.site-header .dropdown-menu {
		float: none;
		background-color: $maroon;
		position: relative;
		border: 0;

		.dropdown-item {
			border: 0;
			color: $dove-grey;
			font-weight: bold;
			padding: 0.425rem	0 0 1rem;
			&:hover,
			&.active {
				color: #fff;
				background-color: transparent;
			}
		}

	}
	.site-header .dropdown:hover {
	.dropdown-menu {
		display: none;
	}
}

}
/*=====  End of navbar  ======*/

/*=======================================
=            header-contacts            =
=======================================*/

.header-contacts {
	padding-top: 1.5rem;
	display: inline-block;
	float: right;
	@media screen and (max-width: 1199px) {
		padding-top: 1rem;
	}
	@media screen and (max-width: 991px) {
		padding-top: 2.3rem;
	}
	.header-contact--email {
		@media screen and (max-width: 991px) {
			display: none;
		}
	}
	.header-contact--phone {
		@media screen and (max-width: 767px) {
			display: none;
		}
	}
	.header-contact--facebook {
		@media screen and (max-width: 1199px) {
			display: none;
		}
		a {
			margin-right: 2rem;
		}
		svg {
			float: none;
			margin: 0;
		}
	}
}


/*=====  End of header-contacts  ======*/






