/*==================================
=            Typography            =
==================================*/

body {
	font-family: 'Open Sans';
}
a,
a:hover,
a:focus,
a:visited,
a:active {
	text-decoration: none;
	color: $maroon;
}
p {
	color: $mine-shaft;
	font-weight: 600;
}
h1 {
	font-size: 3rem;
	margin-bottom: 1.5rem;
}
h1,h2 {
	color: $maroon;
	font-weight: 900;
	text-transform: uppercase;
}
h5 {
	color: $blue;
	font-weight: 600;
}

/*=====  End of Typography  ======*/

/*=============================
=            Fonts            =
=============================*/

@font-face {
	font-family: 'Open Sans';
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSans-Extrabold.eot');
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSans-Extrabold.eot?#iefix') format('embedded-opentype'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSans-Extrabold.woff') format('woff'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSans-Extrabold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSans-Italic.eot');
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSans-Italic.woff') format('woff'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSans-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSans-Light.eot');
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSans-Light.woff') format('woff'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSans-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSans-SemiboldItalic.eot');
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSans-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSans-SemiboldItalic.woff') format('woff'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSans-SemiboldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSansLight-Italic.eot');
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSansLight-Italic.eot?#iefix') format('embedded-opentype'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSansLight-Italic.woff') format('woff'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSansLight-Italic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSans-ExtraboldItalic.eot');
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSans-ExtraboldItalic.eot?#iefix') format('embedded-opentype'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSans-ExtraboldItalic.woff') format('woff'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSans-ExtraboldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSans-Semibold.eot');
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSans-Semibold.woff') format('woff'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSans-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSans-BoldItalic.eot');
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSans-BoldItalic.woff') format('woff'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSans-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSans.eot');
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSans.eot?#iefix') format('embedded-opentype'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSans.woff') format('woff'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSans.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSans-Bold.eot');
	src: url('/wp-content/themes/wsf/dist/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSans-Bold.woff') format('woff'),
		url('/wp-content/themes/wsf/dist/fonts/OpenSans-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}
/*=====  End of Fonts  ======*/
/*=======================================
=            slick overrides            =
=======================================*/

@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;

  src: url('/wp-content/themes/wsf/dist/fonts/slick.eot');
  src: url('/wp-content/themes/wsf/dist/fonts/slick.eot?#iefix') format('embedded-opentype'), url('/wp-content/themes/wsf/dist/fonts/slick.woff') format('woff'), url('/wp-content/themes/wsf/dist/fonts/slick.ttf') format('truetype'), url('/wp-content/themes/wsf/dist/fonts/slick.svg#slick') format('svg');
}

.slick-loading .slick-list {
  background: #fff url('/wp-content/themes/wsf/dist/images/ajax-loader.gif') center center no-repeat;
}
.slick-next,
.slick-prev {
	z-index: 10;
	@media screen and (max-width: 575px) {
		display: none !important;
	}
}
.slick-next {
	width: auto;
	height: auto;
	right: 3%;
}
.slick-prev {
	width: auto;
	height: auto;
	left: 3%;
	transform: rotate(180deg);
	transform-origin: 50% 25%;
}
.slick-dots {
	bottom: 3.5rem;
	display: none !important;
	@media screen and (max-width: 575px) {
		display: block !important;
	}
}
.slick-dots li.slick-active button:before {
	color: $yellow;
	opacity: 1;
}
.slick-dots li button:before {
	font-size: 1rem;
	color: $yellow;
	opacity: 0.5;
}

/*=====  End of slick overrides  ======*/

/*===================================
=            breadcrumbs            =
===================================*/

.breadcrumbs {
	color: #fff;
	a,
	a:focus,
	a:hover,
	a:active,
	a:visited {
		color: $yellow;
		text-decoration: none;
		outline: none;
	}
}

/*=====  End of breadcrumbs  ======*/

/*======================================
=            contact form 7            =
======================================*/

.form-group {
	margin-bottom: 30px;
}

div.wpcf7 {
	margin: 0;
	padding: 0;
}

div.wpcf7-response-output {
	margin: 2em 0.5em 1em;
	padding: 0.2em 1em;
}

div.wpcf7 .screen-reader-response {
	position: absolute;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	width: 1px;
	margin: 0;
	padding: 0;
	border: 0;
}

div.wpcf7-mail-sent-ok {
	border: 2px solid #398f14;
}

div.wpcf7-mail-sent-ng {
	border: 2px solid #ff0000;
}

div.wpcf7-spam-blocked {
	border: 2px solid #ffa500;
}

div.wpcf7-validation-errors {
	border: 2px solid #f7e700;
}

.wpcf7-form-control-wrap {
	position: relative;
}

.wpcf7-form-control,
.wpcf7-form-control:focus {
	outline: none;
	border-color: $nobel-grey;
	border-radius: 0;
	font-weight: normal;
	font-style: italic;
	&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
		font-style: italic;
		font-weight: normal;
		color: $dove-grey;
	}
	&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		font-style: italic;
		font-weight: normal;
		color: $dove-grey;
	}
	&::-moz-placeholder { /* Mozilla Firefox 19+ */
		font-style: italic;
		font-weight: normal;
		color: $dove-grey;
	}
	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		font-style: italic;
		font-weight: normal;
		color: $dove-grey;
	}
}

.wpcf7-submit,
.wpcf7-submit:focus {
	display: block;
	margin-left: auto;
	margin-right: auto;
	border-radius: 1.6rem;
	font-style: normal;
}
span.wpcf7-not-valid-tip {
	color: #f00;
	font-size: 1em;
	display: block;
}

.use-floating-validation-tip span.wpcf7-not-valid-tip {
	position: absolute;
	top: 20%;
	left: 20%;
	z-index: 100;
	border: 1px solid #ff0000;
	background: #fff;
	padding: .2em .8em;
}

span.wpcf7-list-item {
	display: inline-block;
	margin: 0 0 0 1em;
}

span.wpcf7-list-item-label::before,
span.wpcf7-list-item-label::after {
	content: " ";
}

.wpcf7-display-none {
	display: none;
}

div.wpcf7 .ajax-loader {
	visibility: hidden;
	display: inline-block;
	background-image: url('/wp-content/plugins/contact-form-7/images/ajax-loader.gif');
	width: 16px;
	height: 16px;
	border: none;
	padding: 0;
	margin: 0 0 0 4px;
	vertical-align: middle;
}

div.wpcf7 .ajax-loader.is-active {
	visibility: visible;
}

div.wpcf7 div.ajax-error {
	display: none;
}

div.wpcf7 .placeheld {
	color: #888;
}

div.wpcf7 .wpcf7-recaptcha iframe {
	margin-bottom: 0;
}

div.wpcf7 input[type="file"] {
	cursor: pointer;
}

div.wpcf7 input[type="file"]:disabled {
	cursor: default;
}

/*=====  End of contact form 7  ======*/

/*==========================================
=            swipebox overrides            =
==========================================*/

//swipebox overrides
#swipebox-prev,
#swipebox-next,
#swipebox-close {
  background-image: url(/wp-content/themes/wsf/dist/images/swipebox/icons.png);
}
#swipebox-slider .slide-loading {
  background: url(/wp-content/themes/wsf/dist/images/swipebox/loader.gif) no-repeat center center;
}

/*=====  End of swipebox overrides  ======*/







