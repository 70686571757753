$base: 									16;

// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$maroon: 								#72113d;
$yellow: 								#ffc222;
$blue: 									#0b3654;

	// grey
	$dove-grey: 					#707070;
	$mine-shaft: 					#363636;
	$nobel-grey:					#b7b7b7;
	$alto-grey: 					#dedede;
