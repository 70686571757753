.contact-method {
	margin-bottom: 1rem;
	a {
		color: $mine-shaft;
		&:hover {
			color: $maroon;
		}
	}
}
.header-contact {

	display: inline-block;
	position: relative;
	z-index: 500;
	a,
	a:hover {
		color: $maroon;
		font-weight: bold;
		font-size: 1.8rem;
		line-height: 1;
	}
	p {
		color: $blue;
		font-size: 1rem;
		margin-bottom: 0;
	}
	svg {
		margin-right: 0.8rem;
		margin-top: 0.5rem;
		float: left;
	}

}

.services-sidebar .header-contact {
	margin-bottom: 1rem;
	a {
		font-size: 1rem;
		color: #000;
	}
	p {
		font-size: 0.9rem;
	}
	svg {
		width: 2rem;
		height: 2rem;
		fill: $maroon;
	}
}

.services-sidebar .header-contact--email {
	width: 15rem;
}

.header-contact--phone {
	width: 14rem;
}
.header-contact--email {
	width: 21rem;
}




/*===========================================
=            certification logos            =
===========================================*/
.certification-logos {
	background-image: url(../images/buildings-bg.png);
	background-repeat: repeat-x;
	background-position-y: 100%;
}
.certification-logos--home {
	padding-top: 5rem;
	padding-bottom: 4rem;
	background-image: none;
}
.certification-logos__image {
	margin-bottom: 1rem;
}
/*=====  End of certification logos  ======*/


/*==============================
=            banner            =
==============================*/
.banner-container {
	position: relative;
	top: -1.5rem;
	z-index: 10;
}
.banner__slide {
	padding-top: 9rem;
	padding-bottom: 9rem;
	background-size: cover;
	background-position: 50%;
	text-align: center;

	.slide__subtitle {
		color: $yellow;
		font-size: 1.5rem;
		font-style: italic;
		@media screen and (max-width: 575px) {
			font-size: 1.2rem;
		}
	}
	.slide__title {
		color: #fff;
		text-transform: uppercase;
		font-size: 3rem;
		font-weight: bold;
		margin-bottom: 2rem;
		@media screen and (max-width: 575px) {
			font-size: 2rem;
		}
	}
	.btn {
		margin-bottom: 1rem;
		@media screen and (max-width: 575px) {
			display: block;
		}
	}
	.btn + .btn {
		margin-left: 1rem;
		@media screen and (max-width: 575px) {
			margin-left: 0;
		}
	}
}
/*=====  End of banner  ======*/

/*=======================================
=            start build cta            =
=======================================*/

.start-build-cta {
	background-image: url(/wp-content/themes/wsf/dist/images/start-build-bg.jpg);
	background-size: cover;
	background-position: 50%;
	padding-top: 4rem;
	padding-bottom: 4rem;
	text-align: center;
}
.page-id-2 .start-build-cta {
	margin-bottom: 4rem;
}
.start-build-cta__heading {
	margin-bottom: 1rem;
}
.start-build-cta .two-btn-cta {
	padding-top: 1.5rem;
}
.start-build-cta .two-btn-cta > a:last-child {
	padding: 1.1rem 4.7rem;
	position: relative;
	bottom: 1rem;
	@media screen and (max-width: 767px) {
    padding: 1.1rem 3.8rem;
    bottom: 0;
	}
}

/*=====  End of start build cta  ======*/

/*======================================
=            two button cta            =
======================================*/

.two-btn-cta {
	span {
		display: inline-block;
		position: relative;
		bottom: 1rem;
		margin: 0 1rem;
		text-transform: uppercase;
		@media screen and (max-width: 767px) {
			display: block;
			bottom: 0.5rem;
			margin: 1rem 0;
		}
	}
	.btn-lg + .btn-lg {
		margin-left: 1rem;
		@media screen and (max-width: 575px) {
			margin-top: 1rem;
			display: block;
			max-width: 15.75rem;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

/*=====  End of two button cta  ======*/

/*=======================================
=            welcome section            =
=======================================*/

.welcome-section {
	padding: 4rem 0;
	text-align: center;

	.two-btn-cta {
		padding-top: 2rem;
	}
}

.home .welcome-section {
	padding-top: 0;
}

/*=====  End of welcome section  ======*/

/*=====================================================
=            two column content with image            =
=====================================================*/
.two-column-with-image {
	border-top: 1px solid $nobel-grey;
	border-bottom: 1px solid $nobel-grey;
}
.page-id-7 .two-column-with-image {
	border: 0;
}

.two-column-with-image__content {
	padding: 3rem;
	@media screen and (max-width: 767px) {
		padding: 2rem 0;
		text-align: center;
	}
	h2 {
		margin-bottom: 1.5rem;
	}
	a {
		margin-top: 1.5rem;
	}

}
.two-column-with-image__image {
	min-height: 23rem;
	background-size: cover;
	background-position: 50% 15%;
	margin-top: -1px;
	margin-bottom: -1px;

	img {
		display: none;
	}

	@media screen and (max-width: 767px) {
		background-image: none !important;
		min-height: 0;
		padding-bottom: 2rem;
		img {
			display: block;
		}
	}
}
.page-id-7 .two-column-with-image__image {
	margin-top: 0;
	margin-bottom: 0;
}
/*=====  End of two column content with image  ======*/

/*=====================================
=            services grid            =
=====================================*/

.service {
	text-align: center;
	padding-bottom: 3rem;
	img,
	h2 {
		margin-bottom: 2rem;
	}
}

.service__subtitle {
	text-transform: uppercase;
}

/*=====  End of services grid  ======*/

/*==================================
=            our people            =
==================================*/

.our-people {
	padding: 4rem 0;
}
.our-people__content {
	margin-bottom: 3rem;
}
.staff-member {
	padding-bottom: 3rem;
	img,
	h5 {
		margin-bottom: 1.5rem;
	}

}

/*=====  End of our people  ======*/

/*========================================
=            numbered-reasons            =
========================================*/

.numbered-reasons {
	padding: 4rem 0;
	padding-bottom: 3rem;
	h5,
	h1 {
		text-align: center;
	}
}
.reason {
	padding-top: 1rem;
}
.reason__number {
	font-size: 5rem;
	color: $maroon;
	float: left;
	margin-right: 1rem;
	line-height: 1;
}
.reason__content {
	padding-top: 0.6rem;
}

/*=====  End of numbered-reasons  ======*/

/*===========================
=            map            =
===========================*/

#map {
	min-height: 25rem;
	margin-top: 2rem;
}


/*=====  End of map  ======*/

/*====================================
=            contact-info            =
====================================*/
.contact-info {
	padding-top: 2rem;
	padding-left: 2rem;
	padding-bottom: 2rem;
	min-height: 9.5rem;
	border-left: 1px solid $nobel-grey;
	border-bottom: 1px solid $nobel-grey;
	&:last-child {
		border-bottom: 0;
	}
	@media screen and (max-width: 991px) {
		border-left: 0;
		width: 22.5625rem;
		padding-left: 0;
		margin-left: auto;
		margin-right: auto;
	}
	@media screen and (max-width: 575px) {
		min-height: 5.13rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
		width: 18.1625rem;
	}
}
.contact-info__image {
	float: left;
	margin-right: 2rem;
	@media screen and (max-width: 575px) {
		margin-right: 1rem;
	}
}
.contact-info__title {
	padding-top: 0;
	margin-bottom: 0;
	@media screen and (max-width: 575px) {
		font-size: 1rem;
	}
}
.contact-info__content {
	margin-bottom: 0;
	@media screen and (max-width: 575px) {
		font-size: 0.83rem;
	}
}

.contact-info svg {
	@media screen and (max-width: 575px) {
		width: 3.125rem;
		height: 3.125rem;
	}
}

/*=====  End of contact-info  ======*/

/*==============================================
=            service content slider            =
==============================================*/

.services-slider__slide {
	min-height: 31rem;
	background-size: cover;
	@media screen and (max-width: 575px) {
		min-height: 15rem;
	}
}
.services-slider-nav {
	padding-top: 0.5rem;
	padding-bottom: 3rem;
}
.services-slider-nav__item {
	background-size: cover;
	margin: 0 0.25rem;
}
.service-content {
	padding-bottom: 3rem;
}

/*=====  End of service content slider  ======*/

/*========================================
=            services sidebar            =
========================================*/

.services-sidebar {
	border-right: 1px solid $nobel-grey;
	padding-right: 30px;
	padding-bottom: 3rem;
	max-width: 18.75rem;
	margin-left: auto;
	margin-right: auto;
	h5 {
		color: #000;
		text-transform: uppercase;
		text-align: center;
		font-size: 1.2rem;
		margin-bottom: 1rem;
	}
	@media screen and (max-width: 1199px) {
		border-right: 0;
	}
}
.services-sidebar__widget {
	padding-top: 2.5rem;
	&:first-child {
		padding-top: 0;
	}
}

.services-sidebar .any-questions {
	img,
	h5 {
		margin-bottom: 1rem;
	}
}
/*=====  End of services sidebar  ======*/


/*====================================
=            services nav            =
====================================*/

.services-nav {
	border: 1px solid $nobel-grey;
}

.services-nav > a {
	display: block;
	color: #000;
	text-transform: uppercase;
	font-weight: bold;
	padding: 1rem;
	padding-right: 2rem;
	background-image: url(/wp-content/themes/wsf/dist/images/arrow-icon.png);
	background-repeat: no-repeat;
	background-position: 92% 50%;
	&:hover {
		background-color: $maroon;
		color: #fff;
		background-image: none;
	}
}

.page-id-32 .services-nav a:first-child,
.page-id-35 .services-nav a:nth-child(2),
.page-id-37 .services-nav a:nth-child(3),
.page-id-39 .services-nav a:last-child {
	background-color: $maroon;
	color: #fff;
	background-image: none;
}

/*=====  End of services nav  ======*/

/*=======================================
=            project gallery            =
=======================================*/

.projects {
	padding-bottom: 4rem;
}

.gallery__item {

	background-size: cover;
	text-align: center;
	font-weight: bold;
	position: relative;
	margin-bottom: 2rem;

	h4 {
		text-transform: uppercase;
		color: #fff;
		margin-bottom: 2rem;
	}

}

.gallery__item .overlay{

	background-color: rgba(0,0,0,0.8);
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: table;
	opacity: 0;

	&:hover {
		opacity: 1;
	}

	.overlay__content {
		display: table-cell;
		vertical-align: middle;

	}
}

.filter-dropdown {
	.dropdown-menu {
		width: 100%;
	}
	.dropdown-item {
		cursor: pointer;
	}
}

/*=====  End of project gallery  ======*/

/*===============================================
=            project gallery filters            =
===============================================*/

.filter-nav-buttons {
	button {
		margin-bottom: 2rem;
	}
	@media screen and (max-width: 991px) {
		display: none;
	}
}

.filter-mobile {
	margin-bottom: 2rem;
	@media screen and (min-width: 992px) {
		display: none;
	}
}

/*=====  End of project gallery filters  ======*/


