.site-footer {
	background-color: #cfd5d9;
	padding-top: 4rem;
	padding-bottom: 3rem;
	color: #fff;

	.svg {
		fill: $maroon;
		stroke: $maroon;
		display: block;
		margin: 0 auto;
		margin-bottom: 1rem;
		height: 2.625rem;
	}
	span {
		color: $maroon;
		font-weight: bold;
		display: block;
	}
	p {
		margin-bottom: 2rem;
		padding-top: 2rem;
	}
}
.site-footer nav {
	font-size: 0;
	a {
		color: $dove-grey;
		font-size: 1rem;
		padding: 0 1rem;
		text-transform: uppercase;
		font-weight: bold;
		border-right: 1px solid $dove-grey;
		&:last-child {
			border-right: 0;
		}
		@media screen and (max-width: 575px) {
			display: block;
			border: 0;
			padding: 1rem 0;
		}
	}
}
.copyright {
	background-color: $maroon;
	padding: 2rem 0;
	p {
		margin-bottom: 0;
		text-align: center;
		color: #fff;
	}
	a {
		color: $yellow;
	}
}
.footer-logo {
	margin-bottom: 2rem;
}
