.btn {
	border: 2px solid;
	&:focus {
		outline: none !important;
	}
}
.btn-lg {
	min-width: 11rem;
	font-size: 1rem;
	padding-top: 1rem;
	padding-bottom: 1rem;

	svg {
		position: relative;
    float: left;
    margin-top: -0.3125rem;
    padding-right: 0.5rem;
    fill: #fff;
	}

	&:hover svg {
		fill: $yellow;
	}
}
a.btn-yellow,
.btn-yellow,
.btn-yellow:focus {
	background-color: $yellow;
	color: #fff;
	border-color: $yellow;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 1rem;
	&:hover {
		background-color: transparent;
		color: $yellow;
	}
}
a.btn-maroon,
.btn-maroon,
.btn-maroon:focus {
	background-color: $maroon;
	color: #fff;
	border-color: $maroon;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 1rem;
	&:hover,
	&.active {
		background-color: transparent;
		color: $maroon;
	}
}

.btn-round {
	border-radius: 1.6rem;
}
.call-us-btn {
	display: inline-block;
	width: 16rem;
	text-align: left;
	@media screen and (max-width: 767px) {
		width: 14rem;
	}
	svg {
		float: left;
		margin-right: 0.5rem;
		fill: $yellow;
	}
	p {
		margin-bottom: 0;
		padding-top: 0.3rem;
		color: $blue;
	}
	a {
		font-size: 2rem;
		line-height: 1;
		font-weight: bold;
		color: #000;
		@media screen and (max-width: 767px) {
			font-size: 1.6rem;
		}
	}
}

