// Grid system
.main {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
  }
}
// rows with 2px padding in between columns
.rp2 {
  margin-left: -2px;
  margin-right: -2px;
}

.rp2>div[class*=col-] {
  padding-left: 2px;
  padding-right: 2px;
}
